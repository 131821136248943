import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {getToday, retrieveAppointmentInfo, uploadResult} from "./databaseOperation";

const today = getToday();
let appointmentID = 0;

function begin() {
    appointmentID = document.getElementById("appointmentID").value;
    if(appointmentID === "" || appointmentID === null) {
        document.getElementById("appointmentID").classList.remove("is-valid");
        document.getElementById("appointmentID").classList.add("is-invalid");
        document.getElementById("err_a").innerHTML = "Please enter an appointment ID.";
        return;
    }
    let exam = document.getElementById("exam");
    retrieveAppointmentInfo(appointmentID, (data) => {
        if(data != null) {
            var name = data["name"];
            var exam_date = data["appDate"];
            var notes = data["message"];
            document.getElementById("patientName").value = name;
            document.getElementById("examDate").value = exam_date;
            document.getElementById("appointmentID2").value = appointmentID;
            document.getElementById("notes").value = notes;
            exam.hidden = false;
            document.getElementById("physician_submit").disabled = true;
        }
        else {
            document.getElementById("appointmentID").classList.remove("is-valid");
            document.getElementById("appointmentID").classList.add("is-invalid");
            document.getElementById("err_a").innerHTML = "❌ Appointment ID not found. Please try again.";
            exam.hidden = true;
        }
    });
}

function generateReport() {
    let weight = document.getElementById("weight").value;
    let height = document.getElementById("height").value;
    let bloodPressure = document.getElementById("bloodPressure").value;
    let heartbeat = document.getElementById("heartbeat").value;
    let breathRate = document.getElementById("breathRate").value;
    let allergies = document.getElementById("allergies").value;
    let medications = document.getElementById("medications").value;
    let limbs = document.getElementById("limb").value;
    let chest = document.getElementById("chest").value;
    let abdomen = document.getElementById("abdomen").value;
    let notes = document.getElementById("d_notes").value;
    let appDate = document.getElementById("examDate").value;
    let name = document.getElementById("patientName").value;
    let result = {
        "weight": weight,
        "height": height,
        "bloodPressure": bloodPressure,
        "heartbeat": heartbeat,
        "breathRate": breathRate,
        "allergies": allergies,
        "medications": medications,
        "limbs": limbs,
        "chest": chest,
        "abdomen": abdomen,
        "notes": notes,
        "examDate": appDate,
        "name": name
    };
    uploadResult(appointmentID, result);
}

function reset() {
    document.getElementById("exam").hidden = true;
    document.getElementById("weight").value = "";
    document.getElementById("height").value = "";
    document.getElementById("bloodPressure").value = "";
    document.getElementById("heartbeat").value = "";
    document.getElementById("breathRate").value = "";
    document.getElementById("allergies").value = "";
    document.getElementById("medications").value = "";
    document.getElementById("limb").value = "";
    document.getElementById("chest").value = "";
    document.getElementById("abdomen").value = "";
    document.getElementById("notes").value = "";
    document.getElementById("appointmentID").value = "";
    document.getElementById("appointmentID2").value = "";
    document.getElementById("physician_submit").disabled = false;
    document.getElementById("appointmentID").classList.remove("is-invalid");
    document.getElementById("appointmentID").classList.remove("is-valid");
    document.querySelector('input').classList.remove("is-valid");
    document.querySelector('input').classList.remove("is-invalid");
    document.querySelector('textarea').classList.remove("is-valid");
    document.querySelector('textarea').classList.remove("is-invalid");
}

class PhysicianPortal extends Component {

    static handleSuccess() {
        alert("Exam submitted successfully!");
        reset();
    }

    reset() {
        document.getElementById("exam").hidden = true;
        document.getElementById("weight").value = "";
        document.getElementById("height").value = "";
        document.getElementById("bloodPressure").value = "";
        document.getElementById("heartbeat").value = "";
        document.getElementById("breathRate").value = "";
        document.getElementById("allergies").value = "";
        document.getElementById("medications").value = "";
        document.getElementById("limb").value = "";
        document.getElementById("chest").value = "";
        document.getElementById("abdomen").value = "";
        document.getElementById("notes").value = "";
        document.getElementById("appointmentID").value = "";
        document.getElementById("appointmentID2").value = "";
        document.getElementById("physician_submit").disabled = false;
        document.getElementById("appointmentID").classList.remove("is-invalid");
        document.getElementById("appointmentID").classList.remove("is-valid");
        document.querySelector('input').classList.remove("is-valid");
        document.querySelector('input').classList.remove("is-invalid");
        document.querySelector('textarea').classList.remove("is-valid");
        document.querySelector('textarea').classList.remove("is-invalid");
    }

    handleAppointmentIDChange() {
        if(document.getElementById("appointmentID").value === "" || document.getElementById("appointmentID").value === null) {
            document.getElementById("appointmentID").classList.remove("is-valid");
            document.getElementById("appointmentID").classList.add("is-invalid");
            document.getElementById("err_a").innerHTML = "Please enter an appointment ID.";
        }
        else {
            document.getElementById("appointmentID").classList.remove("is-invalid");
            document.getElementById("appointmentID").classList.add("is-valid");
            document.getElementById("err_a").innerHTML = "";
        }
    }

    handleHeightChange() {
        if(document.getElementById("height").value === "" || document.getElementById("height").value === null) {
            document.getElementById("height").classList.remove("is-valid");
            document.getElementById("height").classList.add("is-invalid");
            document.getElementById("err_h").innerHTML = "Please enter height.";
        }
        else if(isNaN(document.getElementById("height").value)) {
            document.getElementById("height").classList.remove("is-valid");
            document.getElementById("height").classList.add("is-invalid");
            document.getElementById("err_h").innerHTML = "Height must be a number.";
        }
        else if(document.getElementById("height").value <= 0) {
            document.getElementById("height").classList.remove("is-valid");
            document.getElementById("height").classList.add("is-invalid");
            document.getElementById("err_h").innerHTML = "Invalid Height.";
        }
        else {
            document.getElementById("height").classList.remove("is-invalid");
            document.getElementById("height").classList.add("is-valid");
            document.getElementById("err_h").innerHTML = "";
        }
    }

    handleWeightChange() {
        if(document.getElementById("weight").value === "" || document.getElementById("weight").value === null) {
            document.getElementById("weight").classList.remove("is-valid");
            document.getElementById("weight").classList.add("is-invalid");
            document.getElementById("err_w").innerHTML = "Please enter weight.";
        }
        else if(isNaN(document.getElementById("weight").value)) {
            document.getElementById("weight").classList.remove("is-valid");
            document.getElementById("weight").classList.add("is-invalid");
            document.getElementById("err_w").innerHTML = "Weight must be a number.";
        }
        else if(document.getElementById("weight").value <= 0) {
            document.getElementById("weight").classList.remove("is-valid");
            document.getElementById("weight").classList.add("is-invalid");
            document.getElementById("err_w").innerHTML = "Invalid weight.";
        }
        else {
            document.getElementById("weight").classList.remove("is-invalid");
            document.getElementById("weight").classList.add("is-valid");
            document.getElementById("err_w").innerHTML = "";
        }
    }

    handleBloodPressureChange() {
        if(document.getElementById("bloodPressure").value === "" || document.getElementById("bloodPressure").value === null) {
            document.getElementById("bloodPressure").classList.remove("is-valid");
            document.getElementById("bloodPressure").classList.add("is-invalid");
            document.getElementById("err_bp").innerHTML = "Please enter blood pressure.";
        }
        else if(isNaN(document.getElementById("bloodPressure").value)) {
            document.getElementById("bloodPressure").classList.remove("is-valid");
            document.getElementById("bloodPressure").classList.add("is-invalid");
            document.getElementById("err_bp").innerHTML = "Blood pressure must be a number.";
        }
        else if(document.getElementById("bloodPressure").value <= 0) {
            document.getElementById("bloodPressure").classList.remove("is-valid");
            document.getElementById("bloodPressure").classList.add("is-invalid");
            document.getElementById("err_bp").innerHTML = "Invalid Blood Pressure.";
        }
        else {
            document.getElementById("bloodPressure").classList.remove("is-invalid");
            document.getElementById("bloodPressure").classList.add("is-valid");
            document.getElementById("err_bp").innerHTML = "";
        }
    }

    handleHeartbeatChange() {
        if(document.getElementById("heartbeat").value === "" || document.getElementById("heartbeat").value === null) {
            document.getElementById("heartbeat").classList.remove("is-valid");
            document.getElementById("heartbeat").classList.add("is-invalid");
            document.getElementById("err_hb").innerHTML = "Please enter heartbeat.";
        }
        else if(isNaN(document.getElementById("heartbeat").value)) {
            document.getElementById("heartbeat").classList.remove("is-valid");
            document.getElementById("heartbeat").classList.add("is-invalid");
            document.getElementById("err_hb").innerHTML = "Heartbeat must be a number.";
        }
        else if(document.getElementById("heartbeat").value <= 0) {
            document.getElementById("heartbeat").classList.remove("is-valid");
            document.getElementById("heartbeat").classList.add("is-invalid");
            document.getElementById("err_hb").innerHTML = "Invalid Heartbeat Rate.";
        }
        else {
            document.getElementById("heartbeat").classList.remove("is-invalid");
            document.getElementById("heartbeat").classList.add("is-valid");
            document.getElementById("err_hb").innerHTML = "";
        }
    }

    handleBreathingRateChange() {
        if(document.getElementById("breathRate").value === "" || document.getElementById("breathRate").value === null) {
            document.getElementById("breathRate").classList.remove("is-valid");
            document.getElementById("breathRate").classList.add("is-invalid");
            document.getElementById("err_br").innerHTML = "Please enter breathing rate.";
        }
        else if(isNaN(document.getElementById("breathRate").value)) {
            document.getElementById("breathRate").classList.remove("is-valid");
            document.getElementById("breathRate").classList.add("is-invalid");
            document.getElementById("err_br").innerHTML = "Breathing rate must be a number.";
        }
        else if(document.getElementById("breathRate").value <= 0) {
            document.getElementById("breathRate").classList.remove("is-valid");
            document.getElementById("breathRate").classList.add("is-invalid");
            document.getElementById("err_br").innerHTML = "Invalid Breathing Rate.";
        }
        else {
            document.getElementById("breathRate").classList.remove("is-invalid");
            document.getElementById("breathRate").classList.add("is-valid");
            document.getElementById("err_br").innerHTML = "";
        }
    }

    handleLimbChange() {
        if(document.getElementById("limb").value === "" || document.getElementById("limb").value === null) {
            document.getElementById("limb").classList.remove("is-valid");
            document.getElementById("limb").classList.add("is-invalid");
            document.getElementById("err_l").innerHTML = "Please enter limb status.";
        }
        else {
            document.getElementById("limb").classList.remove("is-invalid");
            document.getElementById("limb").classList.add("is-valid");
            document.getElementById("err_l").innerHTML = "";
        }
    }

    handleAbdomenChange() {
        if(document.getElementById("abdomen").value === "" || document.getElementById("abdomen").value === null) {
            document.getElementById("abdomen").classList.remove("is-valid");
            document.getElementById("abdomen").classList.add("is-invalid");
            document.getElementById("err_ab").innerHTML = "Please enter abdomen status.";
        }
        else {
            document.getElementById("abdomen").classList.remove("is-invalid");
            document.getElementById("abdomen").classList.add("is-valid");
            document.getElementById("err_ab").innerHTML = "";
        }
    }

    handleChestChange() {
        if(document.getElementById("chest").value === "" || document.getElementById("chest").value === null) {
            document.getElementById("chest").classList.remove("is-valid");
            document.getElementById("chest").classList.add("is-invalid");
            document.getElementById("err_ch").innerHTML = "Please enter chest status.";
        }
        else {
            document.getElementById("chest").classList.remove("is-invalid");
            document.getElementById("chest").classList.add("is-valid");
            document.getElementById("err_ch").innerHTML = "";
        }
    }

    render() {
        return (
            <div>
                <h1 className="display-1 text-center">Physician Portal</h1>
                <div className="container justify-content-center">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="appointmentID">Enter Appointment Number to begin:</label>
                                    <input type="number" className="form-control" id="appointmentID" aria-describedby="Enter appointment ID" onBlur={this.handleAppointmentIDChange}/>
                                    <div className="invalid-feedback" id="err_a"></div>
                                </div>
                                <br/>
                                <button type="button" className="btn btn-primary" id="physician_submit" onClick={begin}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="container" id="exam" hidden="true">
                    <div className="form-group">
                        <label htmlFor="examDate">Exam Date:</label>
                        <input type="date" className="form-control" id="examDate" value={today} disabled='disabled'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="appointmentID">Appointment Number:</label>
                        <input type="number" className="form-control" id="appointmentID2" disabled='disabled'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="patientName">Patient Name:</label>
                        <input type="text" className="form-control" id="patientName" disabled='disabled'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="notes">Additional Notes:</label>
                        <textarea className="form-control" id="notes" rows="3" disabled="disabled"></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="height">Height (in cm):</label>
                        <input type="number" className="form-control" id="height" onBlur={this.handleHeightChange}/>
                        <div className="invalid-feedback" id="err_h"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="weight">Weight (in kg):</label>
                        <input type="number" className="form-control" id="weight" onBlur={this.handleWeightChange}/>
                        <div className="invalid-feedback" id="err_w"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="bloodPressure">Blood Pressure:</label>
                        <input type="text" className="form-control" id="bloodPressure" onBlur={this.handleBloodPressureChange}/>
                        <div className="invalid-feedback" id="err_bp"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="heartbeat">Heartbeat Rate:</label>
                        <input type="number" className="form-control" id="heartbeat" onBlur={this.handleHeartbeatChange}/>
                        <div className="invalid-feedback" id="err_hb"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="breathRate">Breath Rate:</label>
                        <input type="number" className="form-control" id="breathRate" onBlur={this.handleBreathingRateChange}/>
                        <div className="invalid-feedback" id="err_br"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="allergies">Allergies:</label>
                        <input type="text" className="form-control" id="allergies"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="medications">Medications:</label>
                        <input type="text" className="form-control" id="medications"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="limb">Limbs:</label>
                        <select className="form-control" id="limb" onBlur={this.handleLimbChange}>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                        </select>
                        <div className="invalid-feedback" id="err_l"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="abdomen">Abdomen:</label>
                        <select className="form-control" id="abdomen" onBlur={this.handleAbdomenChange}>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                        </select>
                        <div className="invalid-feedback" id="err_ab"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="chest">Chest:</label>
                        <select className="form-control" id="chest" onBlur={this.handleChestChange}>
                            <option value="Normal">Normal</option>
                            <option value="Abnormal">Abnormal</option>
                        </select>
                        <div className="invalid-feedback" id="err_ch"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="notes">Additional Notes:</label>
                        <textarea type="text" className="form-control form-text" id="d_notes"/>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={generateReport}>Submit</button>
                    <button type="button" className="btn btn-primary" onClick={this.reset}>Reset</button>
                </div>
            </div>
        );
    }


}



export default PhysicianPortal;