import React, {Component, Fragment} from "react";
import "./appointment.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {sendAppointment, verifyEmail, verifyPhone, verifyDate} from "./databaseOperation";

class Appointment extends Component {

    createAppointment() {
        const name = document.getElementById("name").value;
        const birth = document.getElementById("birth").value;
        const email = document.getElementById("email").value;
        const phone = document.getElementById("phone").value;
        const gender = document.getElementById("gender")[document.getElementById("gender").selectedIndex].value;
        const appDate = document.getElementById("appDate").value;
        const message = document.getElementById("notes").value;
        sendAppointment(name, birth, email, phone, gender, appDate, message);
    }

    resetForm() {
        document.getElementById("name").value = "";
        document.getElementById("birth").value = "";
        document.getElementById("email").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("appDate").value = "";
        document.getElementById("notes").value = "";
        document.getElementById("gender").selectedIndex = 0;
        document.getElementById("name").classList.remove("is-valid");
        document.getElementById("name").classList.remove("is-invalid");
        document.getElementById("birth").classList.remove("is-valid");
        document.getElementById("birth").classList.remove("is-invalid");
        document.getElementById("email").classList.remove("is-valid");
        document.getElementById("email").classList.remove("is-invalid");
        document.getElementById("phone").classList.remove("is-valid");
        document.getElementById("phone").classList.remove("is-invalid");
        document.getElementById("appDate").classList.remove("is-valid");
        document.getElementById("appDate").classList.remove("is-invalid");
        document.getElementById("result").innerText = "";
        document.getElementById("result").classList.remove("alert");
        document.getElementById("result").classList.remove("alert-success");
        document.getElementById("err_n").innerHTML = "";
        document.getElementById("err_b").innerHTML = "";
        document.getElementById("err_e").innerHTML = "";
        document.getElementById("err_p").innerHTML = "";
        document.getElementById("err_a").innerHTML = "";
        document.getElementById("app_submit").disabled = false;
    }

    static handleSuccess(ID) {
        document.getElementById("app_submit").disabled = true;
        var success = document.getElementById("result");
        success.classList.add("alert");
        success.classList.add("alert-success");
        success.innerText = "✅Appointment created successfully. \nYour appointment number is " + ID + ". \nPlease keep it for future reference.";
    }

    static handleNameOnHover() {
        const name = document.getElementById("name").value;
        if(name === "") {
            document.getElementById("err_n").innerHTML = "Please enter your name";
            document.getElementById("name").classList.remove("is-valid");
            document.getElementById("name").classList.add("is-invalid");
        }
        else {
            document.getElementById("err_n").innerHTML = "";
            document.getElementById("name").classList.remove("is-invalid");
            document.getElementById("name").classList.add("is-valid");
        }
    }

    static handleEmailOnHover() {
        const email = document.getElementById("email").value;
        if(!verifyEmail(email)){
            document.getElementById("err_e").innerHTML = "Please enter a valid email address";
            document.getElementById("email").classList.remove("is-valid");
            document.getElementById("email").classList.add("is-invalid");
        }
        else {
            document.getElementById("err_e").innerHTML = "";
            document.getElementById("email").classList.remove("is-invalid");
            document.getElementById("email").classList.add("is-valid");
        }
    }

    static handlePhoneOnHover() {
        const phone = document.getElementById("phone").value;
        if(!verifyPhone(phone)){
            document.getElementById("err_p").innerHTML = "Please enter a valid phone number";
            document.getElementById("phone").classList.remove("is-valid");
            document.getElementById("phone").classList.add("is-invalid");
        }
        else {
            document.getElementById("err_p").innerHTML = "";
            document.getElementById("phone").classList.remove("is-invalid");
            document.getElementById("phone").classList.add("is-valid");
        }
    }

    static handleAppDateOnHover() {
        const appDate = document.getElementById("appDate").value;
        if(appDate === "" || !verifyDate(appDate, 2)){
            document.getElementById("err_a").innerHTML = "Please enter a valid date.  You must made appointment at least 1 day before.";
            document.getElementById("appDate").classList.remove("is-valid");
            document.getElementById("appDate").classList.add("is-invalid");
        }
        else {
            document.getElementById("err_a").innerHTML = "";
            document.getElementById("appDate").classList.remove("is-invalid");
            document.getElementById("appDate").classList.add("is-valid");
        }
    }

    static handleBirthDateOnHover() {
        const birth = document.getElementById("birth").value;
        if(birth === "" || !verifyDate(birth, 1)){
            document.getElementById("err_b").innerHTML = "Please enter a valid date.";
            document.getElementById("birth").classList.remove("is-valid");
            document.getElementById("birth").classList.add("is-invalid");
        }
        else {
            document.getElementById("err_b").innerHTML = "";
            document.getElementById("birth").classList.remove("is-invalid");
            document.getElementById("birth").classList.add("is-valid");
        }
    }
    
    render() {
        return (
            <Fragment>
                <div className="appointment-page">
                    <h2 id ="title">Appointment Page</h2>
                    <form className="appointment-form">
                        <label htmlFor="name">
                            Name:{" "}
                            <input type="text" required="yes" id="name" className="form-control" onBlur={Appointment.handleNameOnHover}/>
                            <div id="err_n" className="invalid-feedback"></div>
                        </label>
                        <br />
                        <label htmlFor="birth">
                            Birthday:{" "}
                            <input type="date" required="yes" id="birth" className="form-control" onBlur={Appointment.handleBirthDateOnHover}/>
                            <div id="err_b" className="invalid-feedback"></div>
                        </label>
                        <br />
                        <label htmlFor="email">
                            Email:{" "}
                            <input type="email" required="yes" id="email" className="form-control" onBlur={Appointment.handleEmailOnHover}/>
                            <div id="err_e" className="invalid-feedback"></div>
                        </label>
                        <br />
                        <label htmlFor="phone">
                            Phone number: <input type="phone" required="yes" id="phone" className="form-control" onBlur={Appointment.handlePhoneOnHover}/>
                            <div id="err_p" className="invalid-feedback"></div>
                        </label>
                        <br />
                        <label htmlFor="gender">
                            Gender:{" "}
                            <select name="gender" required="yes" id="gender">
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="no">Prefer not to say</option>
                                <option value="unknown">unknown</option>
                            </select>
                            <div id="err_g" className="invalid-feedback"></div>
                        </label>
                        <br />
                        <label htmlFor="appDate">
                            Appointment Date:{" "}
                            <input type="date" required="yes" id="appDate" className="form-control" onBlur={Appointment.handleAppDateOnHover}/>
                            <div id="err_a" className="invalid-feedback"></div>
                        </label>
                        <br />
                        <label htmlFor="notes">Message:</label>
                        <textarea id="notes" name="notes"></textarea>
                        <br />
                        <div className="container mt-3">
                            <div className="row">
                                <button type="button" id="app_submit" onClick={this.createAppointment}>Submit</button>
                                <button type="button" id="app_reset" onClick={this.resetForm}>Reset</button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div id="result"></div>
                        <br />
                    </form>
                </div>
            </Fragment>

        );
    }
}

export default Appointment;
