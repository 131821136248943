import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function PureHealthDiagnostics() {
    return (
        <>
            <h1 className="display-1 text-center">Welcome to Pure Health Diagnostics</h1>
            <br />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <p className="lead">
                            At Pure Health Diagnostics, we are dedicated to providing high-quality
                            health check-ups to help you stay on top of your health. Our services
                            include comprehensive medical check-ups, laboratory tests, and
                            diagnostic imaging, all delivered by experienced medical professionals.
                        </p>
                    </div>
                </div>
            </div>
            <br />
            <div className="row justify-content-center" >
                <div className="col-sm-6 col-md-3">
                    <div className="card text-center">
                        <div className="card-body">
                            <h5 className="card-title">Services</h5>
                            <p className="card-text">Our services include:</p>
                            <p className="card-text">
                                <ul>
                                    <li>Physicals</li>
                                    <li>Drug Testing</li>
                                    <li>Immunizations</li>
                                    <li>Flu Shots</li>
                                    <li>Lab Services</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-3">
                    <div className="card text-center">
                        <div className="card-body">
                            <h5 className="card-title">Locations</h5>
                            <p className="card-text">Our locations include:</p>
                            <p className="card-text">
                                    <ul>
                                        <li>123 Main St, Anytown, CA 12345</li>
                                        <li>456 Oak Ave, Springfield, IL 67890</li>
                                        <li>789 Maple Ln, Portland, OR 12345</li>
                                        <li>321 Elm St, New York, NY 67890</li>
                                        <li>555 Pine Rd, Los Angeles, CA 12345</li>
                                    </ul>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-3">
                    <div className="card text-center">
                        <div className="card-body">
                            <h5 className="card-title">Contact Us</h5>
                            <p className="card-text">Our Contact Info:</p>
                            <p className="card-text">
                                <ul>
                                    <li>Tel：555-123-4567</li>
                                    <li>Fax：555-234-5678</li>
                                    <li>E-Mail：<a href="mailto:contact@fakemail.com">contact@fakemail.com</a></li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PureHealthDiagnostics;
