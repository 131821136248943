import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {retrieveResult} from "./databaseOperation";

let appointmentID = 0;
function resultReturn() {
    appointmentID = document.getElementById("appointmentID").value;
    if(appointmentID == null || appointmentID === "") {
        document.getElementById("err_a").innerText = "Please enter an appointment ID";
        document.getElementById("appointmentID").classList.remove("is-valid");
        document.getElementById("appointmentID").classList.add("is-invalid");
        return;
    }
    retrieveResult(appointmentID, (result) => {
        if(result != null) {
            let JSON_result = {
                "id": appointmentID,
                "name": result["name"],
                "bmi": calculateBMI(result["weight"], result["height"]),
                "weight": result["weight"],
                "height": result["height"],
                "heartBeat": result["heartbeat"],
                "limbs": result["limbs"],
                "abdomen": result["abdomen"],
                "additionalNotes": result["notes"],
                "examDate": result["examDate"]
            };
            DisplayResult(JSON_result);
        }
        else {
            document.getElementById("appointmentID").classList.remove("is-valid");
            document.getElementById("appointmentID").classList.add("is-invalid");
            document.getElementById("err_a").innerHTML = "❌ No result found for this appointment ID. Please try again.";
        }
    });

}

function calculateBMI(weight, height) {
    return  weight / ((height/100) * (height/100));
}

function DisplayResult(JSON_result) {
    document.getElementById("query_submit").disabled = true;
    document.getElementById("r1").innerText = "Result for: "+JSON_result['name']+ " on "+JSON_result['examDate'];
    document.getElementById("appointmentID").classList.remove("is-invalid");
    document.getElementById("appointmentID").classList.add("is-valid");
    document.getElementById("result_region").hidden = false;
    document.getElementById("appID").innerText = 'appointment ID: '+JSON_result['id'];
    document.getElementById("BMI").innerText = 'BMI: '+JSON_result['bmi'];
    document.getElementById("weight").innerText = 'weight (in kg): '+JSON_result['weight'];
    document.getElementById("height").innerText = 'height (in cm): '+JSON_result['height'];
    document.getElementById("heartbeat").innerText = 'heartbeat: '+JSON_result['heartBeat'];
    document.getElementById("limb").innerText = 'limb: '+JSON_result['limbs'];
    document.getElementById("abdomen").innerText = 'abdomen: '+JSON_result['abdomen'];
    document.getElementById("note").innerText = 'additional note: '+JSON_result['additionalNotes'];
    analysisResult(JSON_result['bmi'], JSON_result['heartBeat'], JSON_result['limbs'], JSON_result['abdomen']);
}

function analysisResult(bmi, heartbeat, limb, abdomen) {
    document.getElementById("r2").innerText = "Analysis";
    let bmi_analysis = document.getElementById("A_B");
    let heartbeat_analysis = document.getElementById("A_H");
    let limb_analysis = document.getElementById("A_L");
    let abdomen_analysis = document.getElementById("A_A");
    bmi_analysis.innerText = "BMI: Normal";
    heartbeat_analysis.innerText = "Heartbeat: Normal";
    limb_analysis.innerText = "Limb: Normal";
    abdomen_analysis.innerText = "Abdomen: Normal";
    if(bmi > 25) {
        bmi_analysis.innerText = "BMI: Overweight";
        bmi_analysis.classList.add("list-group-item-warning");
    }
    else if(bmi < 18.5) {
        bmi_analysis.innerText = "BMI: Underweight";
        bmi_analysis.classList.add("list-group-item-warning");
    }
    else {
        bmi_analysis.classList.add("list-group-item-success");
    }
    if(heartbeat > 100) {
        heartbeat_analysis.innerText = "Heartbeat: High";
        heartbeat_analysis.classList.add("list-group-item-warning");
    }
    else if(heartbeat < 60) {
        heartbeat_analysis.innerText = "Heartbeat: Low";
        heartbeat_analysis.classList.add("list-group-item-warning");
    }
    else {
        heartbeat_analysis.classList.add("list-group-item-success");
    }
    if(limb !== 'Normal' && limb !== 'normal') {
        limb_analysis.innerText = 'Limb: '+limb;
        limb_analysis.classList.add("list-group-item-warning");
    }
    else {
        limb_analysis.classList.add("list-group-item-success");
    }
    if(abdomen !== 'Normal' && abdomen !== 'normal') {
        abdomen_analysis.innerText = 'Abdomen: '+abdomen;
        abdomen_analysis.classList.add("list-group-item-warning");
    }
    else {
        abdomen_analysis.classList.add("list-group-item-success");
    }
}

function reset() {
    document.getElementById("appointmentID").classList.remove("is-valid");
    document.getElementById("appointmentID").classList.remove("is-invalid");
    document.getElementById("appointmentID").value = "";
    document.getElementById("err_a").innerText = "";
    document.getElementById("result_region").hidden = true;
    document.getElementById("query_submit").disabled = false;
}


class Result extends Component {
    render() {
        return (
            <>
                <div>
                    <h1 className="display-1 text-center">Query Result</h1>
                    <div className="container justify-content-center">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="appointmentID">Enter Appointment Number to begin:</label>
                                        <input type="number" className="form-control" id="appointmentID" aria-describedby="Enter appointment ID" />
                                        <div className="invalid-feedback" id="err_a"></div>
                                    </div>
                                    <br/>
                                    <button type="button" id="query_submit" className="btn btn-primary" onClick={resultReturn}>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="result_region" className="p-5" hidden="true">
                    <div className="container">
                        <h2 id="r1"></h2>
                        <ul className="list-group">
                            <li className="list-group-item" id="appID">appointment ID</li>
                            <li className="list-group-item" id="BMI">BMI</li>
                            <li className="list-group-item" id="weight">Weight (in kg)</li>
                            <li className="list-group-item" id="height">Height (in cm)</li>
                            <li className="list-group-item" id="heartbeat">HeartBeat</li>
                            <li className="list-group-item" id="limb">Limbs</li>
                            <li className="list-group-item" id="abdomen">Abdomen</li>
                            <li className="list-group-item" id="note">Additional note</li>
                        </ul>
                    </div>
                    <br/>
                    <div className="container">
                        <h2 id="r2"></h2>
                        <ul className="list-group">
                            <li className="list-group-item" id="A_B"></li>
                            <li className="list-group-item" id="A_H"></li>
                            <li className="list-group-item" id="A_L"></li>
                            <li className="list-group-item" id="A_A"></li>
                        </ul>
                    </div>
                <br/>
                <br/>
                    <div className="container">
                        <button type="button" className="btn btn-primary justify-content-center" onClick={reset}>Reset</button>
                    </div>
                </div>
            </>
        );
    }

}






export default Result;
